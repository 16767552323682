@import './vendor/bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss';

//@import "styleLandingInMain.scss";

@font-face {
  font-family: "MyriadProLight";
  src: url("/fonts/MyriadPro-Light.otf");
}

@font-face {
  font-family: "MyriadProRegular";
  src: url("/fonts/MyriadProRegular/MyriadProRegular.eot");
  src: url("/fonts/MyriadProRegular/MyriadProRegular.eot?#iefix")format("embedded-opentype"),
  url("/fonts/MyriadProRegular/MyriadProRegular.woff") format("woff"),
  url("/fonts/MyriadProRegular/MyriadProRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "MyriadProBold"; */
@font-face {
  font-family: "MyriadProBold";
  src: url("/fonts/MyriadProBold/MyriadProBold.eot");
  src: url("/fonts/MyriadProBold/MyriadProBold.eot?#iefix")format("embedded-opentype"),
  url("/fonts/MyriadProBold/MyriadProBold.woff") format("woff"),
  url("/fonts/MyriadProBold/MyriadProBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "MyriadProItalic"; */
@font-face {
  font-family: "MyriadProItalic";
  src: url("/fonts/MyriadProItalic/MyriadProItalic.eot");
  src: url("/fonts/MyriadProItalic/MyriadProItalic.eot?#iefix")format("embedded-opentype"),
  url("/fonts/MyriadProItalic/MyriadProItalic.woff") format("woff"),
  url("/fonts/MyriadProItalic/MyriadProItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "MyriadProSemiBold"; */
@font-face {
  font-family: "MyriadProSemiBold";
  src: url("/fonts/MyriadProSemiBold/MyriadProSemiBold.eot");
  src: url("/fonts/MyriadProSemiBold/MyriadProSemiBold.eot?#iefix")format("embedded-opentype"),
  url("/fonts/MyriadProSemiBold/MyriadProSemiBold.woff") format("woff"),
  url("/fonts/MyriadProSemiBold/MyriadProSemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "MyriadProBoldItalic"; */
@font-face {
  font-family: "MyriadProBoldItalic";
  src: url("/fonts/MyriadProBoldItalic/MyriadProBoldItalic.eot");
  src: url("/fonts/MyriadProBoldItalic/MyriadProBoldItalic.eot?#iefix")format("embedded-opentype"),
  url("/fonts/MyriadProBoldItalic/MyriadProBoldItalic.woff") format("woff"),
  url("/fonts/MyriadProBoldItalic/MyriadProBoldItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "MyriadProSemiBoldItalic"; */
@font-face {
  font-family: "MyriadProSemiBoldItalic";
  src: url("/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.eot");
  src: url("/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.eot?#iefix")format("embedded-opentype"),
  url("/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.woff") format("woff"),
  url("/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

.body-site{
  font-family: MyriadProLight;
}
.menu-item{
  width: 90px;
  margin: 0 10px;
  padding: 10px;
  height: 120px;
  text-align: center;
}
.menu-item-block{
  width: 66px;
  height: 66px;
  margin-bottom: 10px;
  -webkit-transition:background 0.2s linear;
  transition:background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
}
#header-main{
  margin: 0;
  padding: 30px 50px 20px 50px;
  font-size: 0.9em;
  background: #fff;
}
.menu-icon{
  width: 66px;
  height: 66px;
  margin: 10px auto 10px auto;
  display: block;
  background-position: -65px 0;
  background-repeat: no-repeat;
}
.warehousing-icon{
  background-image: url(/images/wareh_icon.png);
}
.purchasing-icon{
  background-image: url(/images/purchasing_icon.png);
}
.inventory-icon{
  background-image: url(/images/inventory_icon.png);
}
.perfomanse-icon{
  background-image: url(/images/perfomance_icon.png);
}
.pricing-icon{
  background-image: url(/images/prising_icon.png);
}
.shipping-icon{
  background-image: url(/images/shipping_icon.png);
}
.reports-icon{
  background-image: url(/images/reports_icon.png);
}
.accounting-icon{
  background-image: url(/images/accounting_icon.png);
}
.settings-icon{
  background-image: url(/images/setting_icon.png);
}
.login-icon{
  background-image: url(/images/login_icon.png);
}
.menu-item a:hover{
  text-decoration: none;
}
.menu-item:hover .menu-icon{
  background-position:0 0;
}
.menu-item.active .menu-icon{
  background-position:0 0;
}
.main_content{
  color: #868686;
  font-size: 16px;
  position: relative;
}
.auth-form .panel{
  border: 0;
  border-radius: 15px;
}
.auth-form .panel-body{
  padding: 15px 40px;
}
.auth-form .header-login{
  text-align: center;
  font-size: 3em;
  color: #5e5e5e;
}
.sing-up-text{
  font-size: 0.9em;
  text-align: center;
  margin: 15px 0 25px 0;
  color: #5B5B5B;
}
.auth-form a{
  color: #FD366D;
  font-size: 1.1em;
}
.auth-form .form-group a{
  font-size: 1em;
}
.auth-form .form-control{
  background-color: #F9F9F9;
  border: 2px solid #EFEFEF;
  border-radius: 12px;
  height: 45px;
  padding: 6px 15px;
}
.auth-form .btn{
  width: 100%;
  border-radius: 13px;
  padding: 20px 0;
  text-transform: uppercase;
  font-size: 1.3em;
  background-color: #FD366D;
  border-color: #FD366D;
  color: #fff;
}
.auth-form .btn:focus{
  outline: none;
}
.auth-form .btn:active{
  box-shadow:none;
}
.copyright{
  font-size: 0.8em;
  text-align: center;
  margin-top: -10px;
  color: #929292;
}
.centered{
  text-align: center;
}
.colored-style{
  color: #FD366D;
}
a {
  color: #1AC3F6;
}

/* inventory page */
.new-pos-form .form-group{
  float: left;
  margin: 10px 5px 15px 5px;
  width: 20%;
}
.new-pos-form .form-control{
  border: 0;
  border-radius: 10px;
}
.new-pos-form .btn{
  width: 65px;
  border-radius: 13px;
  padding: 6px 0;
  background-color: #FD366D;
  border-color: #FD366D;
  color: #fff;
  position: relative;
  top: 20px;
}
.new-pos-block{
  font-size: 0.9em;
  margin-bottom: 25px;
}
.new-pos-form .control-label{
  font-weight: 100;
}
.inventory-table .table > tbody > tr > th, .inventory-table .table > tbody > tr > td{
  border: 0;
}
.inventory-table{
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 10px;
}
.inventory-table .table > tbody > tr > th{
  color: #FD366D;
}
.icon-setting{
  background: url('/images/edit-pencil.png');
  display: inline-block;
  width: 15px;
  height: 15px;
  background-size: cover;
  margin-right: 5px;
  position: relative;
  top: 3px;
}
a:hover, a:focus {
  color: #1AC3F6;
}
.icon-add{
  color: #fff;
  background: #FD366D;
  padding: 6px 14px;
  font-size: 2.8em;
  width: 50px;
  height: 50px;
  display: inline-block;
  line-height: 1em;
  border-radius: 25px;
}
.color-FD366D{
  color: #FD366D;
}
.color-FD366D:hover{
  color: #FD366D;
}
.new-pos-form{
  margin-top: 15px;
  display: none;
}
.new-pos-block .add-text{
  position: relative;
  top: -8px;
  font-size: 1.1em;
  left: 10px;
}
/* inventory page */

.width_100{
  width:100%;
}
#try-it{
  color: #868686;
  font-size: 17px;
  font-weight: 100;
}
#try-it .modal-body{
  padding: 0 150px;
}
#try-it .modal-body h2{
  font-family: MyriadProRegular;
}
#try-it .modal-header{
  border-bottom: 0;
}
.img-try-it-modal{
  width: 330px;
  margin: auto;
}
.text-modal{
  margin: 25px 0;
}
.home-block-5 .header-text{
  margin-bottom: 30px;
  font-size: 25px;
  font-family: MyriadProRegular;
}
.home-block-5{
  padding-top: 30px;
}
.home-block-1{
  margin-top: 40px;
}
.home-block-1 h1{
  font-family: MyriadProRegular;
  font-size: 55px;
}
.centered{
  text-align: center;
}
.home-block-2{
  background-color: #f4f4f4;
  margin-top: -5px;
  padding: 30px 0 75px 0;
}
.try-it-btn{
  background-color: #00BEF6;
  border-color: #00BEF6;
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 7px;
  color: #fff;
}
.try-it-btn:hover,
.try-it-btn:focus,
.try-it-btn:active{
  background-color: #00BEF6;
  border-color: #00BEF6;
  color: #fff;
}
.home-block-4{
  background-color: #f4f4f4;
  padding: 60px 0;
}
.block-try-it-btn{
  margin: 30px 0 5px 0;
}
.home-block-2 h2{
  margin-bottom: 25px;
  font-family: MyriadProRegular;
}
.home-block-4 h2{
  font-family: MyriadProRegular;
  line-height: 38px;
}
.home-block-4 ul{
  list-style: none;
  padding-left: 80px;
}
.home-block-4 ul > li {
  padding: 4px 0;
}
.footer-text{
  padding: 110px 0 20px 0;
  background-color: #f4f4f4;
  color: #868686;
  font-size: 19px;
  font-family: MyriadProRegular;
  text-align: center;
}
.align-right{
  text-align: right;
}
.width-60{
  max-width: 60px;
}
.col-sm-8.col-important{
  width: 66.66667%;
}
.margin-left-0{
  margin-left: 0!important;
}
.warehouse.icon-add {
  color: #fff;
  background: #00BEF6;
  padding: 4px 8px;
  font-size: 30px;
  width: 34px;
  height: 34px;
  display: inline-block;
  line-height: 1em;
  border-radius: 20px;
  float: left;
  cursor: pointer;
}
.add-new-sku .add-text{
  display: inline-block;
  padding: 7px 10px;
}
.inline-block{
  display: inline-block;
}
.warehouse .btn-primary,
.warehouse .btn-primary:hover,
.warehouse .btn-primary:active,
.warehouse .btn-primary:focus{
  color: #fff;
  background-color: #00BEF6;
  border-color: #00BEF6;
}

.wrapper{
  display: table;
  width: 100%;
}
#wh-list{
  background-color: #939498;
  color: #000;
  display: table-cell;
  float: none;
}
#sku-list-block{
  background-color: #A5A9AC;
  color: #000;
  display: table-cell;
  float: none;
}
#info-block{
  display: table-cell;
  float: none;
}
@media(max-width: 768px){
  .wrapper{
    display: block;
    width: auto;
  }
  #wh-list{
    background-color: #939498;
    color: #000;
    display: block;
    float: left;
  }
  #sku-list-block{
    background-color: #A5A9AC;
    color: #000;
    display: block;
    float: left;
  }
  #info-block{
    display: block;
    float: left;
  }
}
.stat-table > thead > tr > th {
  border: 0;
  font-size: 1.4em;
  padding: 0 0;
  text-align: center;
}
.stat-table {
  text-align: center;
  margin-bottom: 5px;
}
.buy-block {
  height: 35px;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #00AEEF;
  margin-top: 12px;
}
.buy-block input[type='text'] {
  padding: 2px 5px;
  height: 25px;
}
.buy-block input[type='submit'] {
  background: transparent;
  color: #fff;
  font-size: 1.3em;
  padding: 0;
}
.add-control:hover{
  cursor: pointer;
  text-decoration: underline;
  color: #1AC3F6;
}
.add-control{
  color: #1AC3F6;
}
.wh_input input{
  border-radius: 20px 0 0 20px;
  border-right-width: 0px;
  height: 30px;
  box-shadow: none;
}
.wh_input .input-group-addon{
  border-radius: 0px 20px 20px 0px;
  background-color: #ffffff;
  border-left-width: 0px;
  height: 30px;
}
.wh_input{
  margin-bottom: 15px;
}
#sku-detail .table > tbody > tr > td{
  border-top: 0;
}

/* Purchasing */
.stat-table > thead > tr > th {
  border: 0;
  font-size: 1.4em;
  padding: 0 0;
  text-align: center;
}
.stat-table {
  text-align: center;
  margin-bottom: 5px;
}
.buy-block {
  height: 35px;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #00AEEF;
  margin-top: 12px;
}
.buy-block input[type='text'] {
  padding: 2px 5px;
  height: 25px;
}
.buy-block input[type='submit'] {
  background: transparent;
  color: #fff;
  font-size: 1.3em;
  padding: 0;
}
#purchasing-detail .text-success{
  margin-left: 15px;
}
#purchasing-acardeon .form-group.form-for-search{
  width: 94%;
  margin: 15px 3%;
}
#purchasing-acardeon h3,
#purchasing-acardeon h4{
  padding: 10px;
}
#purchasing-acardeon .sku-list{
  width: 94%;
  margin: 0 auto;
}
#purchasing-acardeon .sku-list > tbody > tr > th{
  border: 0;
}
#purchasing-acardeon .sku-list > tbody > tr.no-hover:hover {
  background-color: transparent;
}
#purchasing-acardeon .form-for-search .form-control{
  padding: 0 10px;
  box-sizing: border-box;
}
.centered{
  text-align: center;
}
.width-30 {
  max-width: 30px;
}
footer{
  display: none;
}
#purchasing-info-block{
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 30px 30px 0 700px;
}
#purchasing-acardeon .slide > div{
  overflow: auto;
}
#purchasing-acardeon{
  margin-left: -15px;
  float: left;
  z-index: 99;
  position: relative;
}
.liteAccordion.basic #purchasing-ready-li > h2,
#purchasing-ready{
  background: #57585A;
  color: #000;
}
.liteAccordion.basic #purchasing-pending-li > h2,
#purchasing-pending{
  background: #6D6E72;
  color: #000;
}
.liteAccordion.basic #purchasing-confirmed-li > h2,
#purchasing-confirmed{
  background: #808185;
  color: #000;
}
.liteAccordion.basic #purchasing-proforma-li > h2,
#purchasing-proforma{
  background: #939498;
  color: #000;
}
.liteAccordion.basic #purchasing-made-li > h2,
#purchasing-made{
  background: #A5A9AC;
  color: #000;
}
.liteAccordion.basic #purchasing-refund-li > h2,
#purchasing-refund{
  background: #BCBDBF;
  color: #000;
}
.liteAccordion.basic #empty-li > h2{
  background: #fff;
  color: #fff;
  cursor: default;
}
.empty{
  background: #fff;
  color: #fff;
}
#purchasing-acardeon .slide > h2 span{
  -webkit-writing-mode: vertical-rl;
  writing-mode: tb-rl;
  text-align: center;
  padding-right: 0;
  height: 100%;
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
}
#purchasing-acardeon .slide > h2 span.number{
  right: 25px;
  font-weight: bold;
  color: red;
}
#purchasing-list ul{
  list-style: none;
  padding: 0;
  min-height: 500px;
}
#purchasing-list ul > li > a{
  color: #000;
}
#purchasing-list li{
  padding: 5px;
}
#purchasing-list ul > li > a.active{
  color: #FFF;
}
.wrapper{
  display: table;
  width: 100%;
}
#purchasing-list{
  background-color: #939498;
  color: #000;
  display: table-cell;
  float: none;
}
#info-block{
  display: table-cell;
  float: none;
}
/* Purchasing */
